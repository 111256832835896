export interface UserModel {
  userId: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  userType: string;
  profileImage: string;
  defaultCompanyId: number;
  profileComplete: boolean;
  superAdmin: boolean;
  admin: boolean;
}

export class User {
  constructor(user: any) {
    Object.keys(user).forEach((key) => {
      this[key] = user[key];
    });
  }
}
