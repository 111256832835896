import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor() { }

  public static get apiBaseUrl(): string {
    return environment.apiUrl;
  }

  public static get mediaBaseUrl(): string {
    return environment.mediaBaseUrl;
  }

  public static get marineAPIURL(): string {
    return environment.marineAPIURL;
  }
}
