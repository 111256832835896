import { Injectable } from "@angular/core";
import { BnNgIdleService } from "bn-ng-idle";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class IdleUserDetectorService {
  private idleTimeOut$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  constructor(private _bnNgIdleService: BnNgIdleService) {
    const idleTimeOut = environment.sessionTimeOutInSeconds;
    this._bnNgIdleService.startWatching(idleTimeOut).subscribe((isTimeOut) => {
      if (isTimeOut) {
        this.idleTimeOut$.next(true);
      }
    });
  }

  get idleTimeOut() {
    return this.idleTimeOut$.asObservable();
  }
  reset() {
    this._bnNgIdleService.resetTimer();
    this.idleTimeOut$.next(false);
  }

  stop() {
    this._bnNgIdleService.stopTimer();
    this.idleTimeOut$.next(false);
  }
}
