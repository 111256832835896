import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {RequesterService} from './requester.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbs$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private requesterService: RequesterService
  ) {
    this.router.events.subscribe(event => {
      // console.log(event)
      if (event instanceof NavigationEnd) {
        this.populateBreadcrumb(event.urlAfterRedirects);
      }
    });
  }
  private async populateBreadcrumb(url: string) {
    console.log(url);
    const breadcrumbData = await this.getBreadcrumbArrayByUrl(url);
    this.breadcrumbs$.next(breadcrumbData);
  }
  public get onBreadcrumb(): Observable<any[]> {
    return this.breadcrumbs$.asObservable();
  }

  private async getBreadcrumbArrayByUrl(url: string): Promise<any[]> {
    const finalBreadcrumb: any[] = [];
    if (url === '/panel/dashboard') {
      return [];
    }
    let rfqId = null;
    finalBreadcrumb.push({
      url: '/panel/dashboard',
      name: 'Dashboard'
    });
    // Insert Job Details related Routes
    if (/\/panel\/po-process\/.*/.test(url)) {
      rfqId = url.split('/')[3];
    } else if (/\/panel\/(job-details|attachments|invoice|work-space)\/.*\/?/.test(url)) {
      rfqId = url.split('/').pop();
    }
    let pages = [];

    if (rfqId) {
      const rfqDetails = await this.getRfqDetails(rfqId);
      // console.log(rfqDetails);
      finalBreadcrumb.push({
        url: `/panel/job-details/${rfqId}`,
        name: 'Job Details'
      });
      pages = [
        {
          url: `/panel/po-process/${rfqId}/shortlisted-bids`,
          name: 'Bids on Job',
          key: 'shortlisted-bids',
          status: rfqDetails.bid,
          active: false
        },
        {
          url: `/panel/po-process/${rfqId}/po/${rfqDetails.awardedBidId}`,
          name: 'PO Details',
          key: 'view-po',
          status: rfqDetails.po,
          active: false
        },
        {
          url: `/panel/work-space/${rfqId}`,
          name: 'Workspace',
          key: 'work-space',
          status: rfqDetails.workspace,
          active: false
        },
        {
          url: `/panel/invoice/${rfqId}`,
          name: 'Invoice',
          key: 'invoice',
          status: rfqDetails.invoice,
          active: false
        },
        {
          url: `/panel/attachments/${rfqId}`,
          name: 'Attachments',
          key: 'attachments',
          status: true,
          active: false
        },
      ];
    }



    if (/\/panel\/job-details\/.*\/?/.test(url)) {
      finalBreadcrumb.push({
        url: null,
        name: 'Select',
        children: pages
      });
    }
    if (/\/panel\/work-space\/.*\/?/.test(url)) {
      const foundIndex = pages.findIndex(x => x.key === 'work-space');
      if (foundIndex !== -1) {
        pages[foundIndex].active = true;
        finalBreadcrumb.push({
          url: null,
          name: pages[foundIndex].name,
          children: pages
        });
      }
    }


    if (/\/panel\/invoice\/.*\/?/.test(url)  ) {
      const foundIndex = pages.findIndex(x => x.key === 'invoice');
      if (foundIndex !== -1) {
        pages[foundIndex].active = true;
        finalBreadcrumb.push({
          url: null,
          name: pages[foundIndex].name,
          children: pages
        });
      }
    }

    if (/\/panel\/po-process\/.*\/po\/.*\/?/.test(url)  ) {
      const foundIndex = pages.findIndex(x => x.key === 'view-po');
      if (foundIndex !== -1) {
        pages[foundIndex].active = true;
        finalBreadcrumb.push({
          url: null,
          name: pages[foundIndex].name,
          children: pages
        });
      }
    }

    if (/\/panel\/po-process\/.*\/shortlisted-bids\/?/.test(url)  ) {
      const foundIndex = pages.findIndex(x => x.key === 'shortlisted-bids');
      if (foundIndex !== -1) {
        pages[foundIndex].active = true;
        finalBreadcrumb.push({
          url: null,
          name: pages[foundIndex].name,
          children: pages
        });
      }
    }

    if (/\/panel\/attachments\/.*\/?/.test(url)  ) {
      const foundIndex = pages.findIndex(x => x.key === 'attachments');
      if (foundIndex !== -1) {
        pages[foundIndex].active = true;
        finalBreadcrumb.push({
          url: null,
          name: pages[foundIndex].name,
          children: pages
        });
      }
    }

    return finalBreadcrumb;
  }

  async getRfqDetails(rfqId): Promise<any> {
    return (await this.requesterService.request('get', 'jobs/rfqs/' + rfqId + '/breadcrumbs').toPromise()).data;
  }
}
