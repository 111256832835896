import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {ChannelData} from "stream-chat";

@Injectable({
  providedIn: 'root'
})
export class ChatDispatcherService {
  public channel: Subject<ChannelData> = new Subject<ChannelData>();
  public notificationCounter: BehaviorSubject<{count: number, channelList: ChannelData[]}> = new BehaviorSubject<{count: number, channelList: ChannelData[]}>({count: 0, channelList: []});
  public openChat: Subject<any> = new Subject<any>();
  public refreshCounters: Subject<any> = new Subject<any>();
  constructor() { }
  public createOrUpdateChannel(channelData: ChannelData) {
    this.channel.next({ ...channelData, data: channelData});
  }
}
