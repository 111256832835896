import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PdfPreviewRenderComponent } from '../pdf-preview-render/pdf-preview-render.component';
import { NzModalService } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {

  @Input() src: string;
  @Input() fileName?: string;
  @Input() width: string = '100%';
  @Input() height?: string;
  apiBaseUrl = environment.apiUrl + '/uploads/';
  dynamicStyle: any = {};
  type: string = 'image';
  brokenImage = false;
  @Input() deleteEnabled: boolean = false;
  @Input() downloadEnabled: boolean = true;
  @Input() selectEnabled: boolean = false;
  @Input() fileDetail : any;
  @Output() fileDelete: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private _modalService : NzModalService

  ) {

  }

  ngOnInit() {
    const ext = this.src.slice((this.src.lastIndexOf(".") - 1 >>> 0) + 2);
    this.type = ['png', 'jpg', 'jpeg', 'gif'].includes(ext) ? 'image': 'file';
    this.dynamicStyle.width = this.width;
    if( this.height) {
      this.dynamicStyle.height = this.height;
    }
  }
  defaultImage(e) {
    console.log(e);
    this.brokenImage = true;
  }

  downloadFile() {
    window.open(this.apiBaseUrl + this.src);
  }

  pdfModalOpen(filePath: string , fileName : string) : void {
    const modal = this._modalService.create<PdfPreviewRenderComponent>({
      nzTitle : `Preview - ${fileName}`,
      nzContent : PdfPreviewRenderComponent,
      nzWidth: '975px',
      nzBodyStyle: {height: '700px'},
      nzStyle: { top: '20px'},
      nzMaskClosable: false,
      nzCentered: true,
      nzComponentParams: {
        filePath : filePath
      },
      nzOkText: 'Close Preview',
      nzCancelDisabled: true
    });
    modal.afterClose.subscribe();
  }

}
