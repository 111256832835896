import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {PushNotificationsModule} from 'ng-push';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {  NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { DefaultComponent } from './layouts/default/default.component';
import { NoSidebarComponent } from './layouts/no-sidebar/no-sidebar.component';
import { NotFoundComponent } from './misc/not-found/not-found.component';
import { HomeComponent } from './misc/frontend/home/home.component';
import { HeaderComponent } from './layouts/no-sidebar/header/header.component';
import { FooterComponent } from './layouts/no-sidebar/footer/footer.component';
import { EditProfileModalComponent } from './main/user/profile/edit-profile-modal/edit-profile-modal.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { SharedModuleModule } from './common/shared-module/shared-module.module';
import {ChatModule} from './main/chat/chat.module';
import {GlobalSearchComponent} from './layouts/no-sidebar/header/global-search/global-search.component';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
// import { FileuploadComponent } from './noauth/newjob/fileupload/fileupload.component';
import { BnNgIdleService } from 'bn-ng-idle';

const config: SocketIoConfig = { url: environment.apiUrl, options: {} };

registerLocaleData(en);
@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    NoSidebarComponent,
    NotFoundComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ChangePasswordComponent,
    GlobalSearchComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    SharedModuleModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ChatModule,
    // PushNotificationsModule,
    SocketIoModule.forRoot(config)
  ],
  entryComponents: [ChangePasswordComponent],
  providers: [{ provide: NZ_I18N, useValue: en_US },BnNgIdleService],
  bootstrap: [AppComponent]
})
export class AppModule { }
