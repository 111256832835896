import { Component, OnInit, SimpleChanges, OnChanges } from "@angular/core";
import { AuthService } from "../../common/services/auth.service";
import { User } from "../../common/models/user";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MenuService } from "src/app/common/services/menu.service";
import { BreadcrumbService } from "../../common/services/breadcrumb.service";
import { StartChatService } from "src/app/common/services/start-chat.service";
import { CommunicationService } from "src/app/common/services/communication.service";
import { ChatDispatcherService } from "../../main/chat/services/chat-dispatcher.service";
import { includes } from "lodash";

@Component({
  selector: "app-default",
  templateUrl: "./default.component.html",
  styleUrls: ["./default.component.scss"],
})
export class DefaultComponent implements OnInit {
  userDetails: User;
  menuData: any[] = [];
  currentUrl = "";
  isCollapsed = false;
  breadcrumbArr: any[] = [];
  chatIconType: string = "message";
  currentRfq: any = '';
  masterOpen = true;
  unreadCount = 0;
  constructor(
    private authService: AuthService,
    private router: Router,
    private menuService: MenuService,
    private breadcrumbService: BreadcrumbService,
    private startChatService: StartChatService,
    private communicationService: CommunicationService,
    private route: ActivatedRoute,
    private _chatDispatcherService: ChatDispatcherService
  ) {}

  ngOnInit() {
    this.userDetails = AuthService.getUserDetails();
    this.menuData = this.getMenu();
    this.currentUrl = this.router.url;
    console.log("this.currentUrl:", this.currentUrl);
    this.authService.authState.subscribe((loggedIn) => {
      this.userDetails = AuthService.getUserDetails();
      this.menuData = this.getMenu();
    });

    this._chatDispatcherService.notificationCounter.subscribe(
      (subscribedData) => {
        this.unreadCount = subscribedData.count;
      }
    );

    this.breadcrumbService.onBreadcrumb.subscribe((data) => {
      this.breadcrumbArr = data;
    });
    this.startChatService.openRFQ.subscribe((data) => {
      this.currentRfq = data !== "0" ? data : "";
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  get isBidListPage(): boolean {
    return (
      this.currentUrl.includes("shortlisted-bids") ||
      this.currentUrl.includes("po-process") ||
      this.currentUrl.includes("attachments") ||
      this.currentUrl.includes("profile") ||
      this.currentUrl.includes('work-space') ||
      this.currentUrl.includes('request') ||
      this.currentUrl.includes('invoice') ||
      this.currentUrl.includes('details/chats') ||
      this.currentUrl.includes('new-job')
    );
  }

  getMenu(): any[] {
    if (AuthService.isSuperAdmin() || AuthService.isAdmin()) {
      return this.menuService.getLeftMenu();
    }
    const otherUsersMenuItems = [
      "dashboard",
      "newjob",
      "myJobs",
      "invitedVendors",
    ];
    // if(this.userDetails !== null || this.userDetails !== undefined) {
    if (
      this.userDetails !== null &&
      this.userDetails["subRole"] !== null &&
      this.userDetails["subRole"] !== "master"
    ) {
      otherUsersMenuItems.push("jobRequests");
    }
    // }

    return this.menuService.getLeftMenu(otherUsersMenuItems);
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl("/auth/login");
  }

  profile() {
    this.router.navigateByUrl("/panel/user/profile");
  }

  goToLink(url: string) {
    console.log(url);
    this.router.navigateByUrl(url);
  }

  onMenuOpenClose(event) {
    this.masterOpen = !this.masterOpen;
  }

  openChat() {
    this.chatIconType = "loading";
    setTimeout(() => {
      this.chatIconType = "message";
    }, 3000);
    this.startChatService.openChat.next(null);
  }

  download() {
    this.communicationService.downloadSubject.next(null);
  }
}
