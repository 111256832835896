import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, first, take, takeWhile } from "rxjs/operators";
import { AuthService } from "./common/services/auth.service";
import { IdleUserDetectorService } from "./common/services/idle-user-detector.service";
import { SocketService } from "./common/services/socket.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  url: string;
  
  constructor(
    private socketService: SocketService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _idleUserDetectorService: IdleUserDetectorService,
    private _authService: AuthService,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.token) {
        const mainUrl = this.url.split("?")[0];
        if (mainUrl !== "/auth/verify-registration") {
          this.router.navigate(["auth/quick-login"], {
            queryParams: { ...params, redirectTo: mainUrl },
          });
        }
      }
    });
    router.events.pipe(first()).subscribe((val: any) => {
      const { url } = val;
      this.url = url;
    });
  }
  ngOnInit() {
    this.socketService.connect();
    this.socketService.authentication();
    this._authService.startIdleTimer();
  }

  ngOnDestroy() {
     this._idleUserDetectorService.stop();
  }
}
