import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-media-box',
  templateUrl: './media-box.component.html',
  styleUrls: ['./media-box.component.scss']
})
export class MediaBoxComponent implements OnInit {
  @Input() files: any[] = [];
  @Output() filesChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() itemsPerLine: number = 3;
  @Output() fileDelete: EventEmitter<number> = new EventEmitter<number>();
  @Input() deleteEnabled: boolean = false;
  @Input() downloadEnabled: boolean = true;
  @Input() selectEnabled: boolean = false;
  gridStyle = {
    width: (100 / this.itemsPerLine) + '%',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
  constructor() { }

  ngOnInit() {
    this.gridStyle.width = (100 / this.itemsPerLine) + '%';
    console.log(this.itemsPerLine, this.gridStyle)
  }
  onSelectChange() {
    this.filesChange.emit(this.files);
  }

  deleteFile(index: number) {
    this.fileDelete.emit(index);
  }

}
